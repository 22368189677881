import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome'
import { faFacebookF, faLinkedinIn, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import GlobalJSON from "../content/global.json"
import TrabusIcon from "../images/trabus_icon.svg"
import RipplegoLogo from "../images/whiteRipplego.svg"

export default function Footer() {
    return (
        <div id="footer">
            <Container className="text-center my-4">
                <Row className="justify-content-between">
                    <Col lg={4}>
                        <Link to="/" className="">
                        <div className='d-flex align-items-center mb-3 justify-content-center'>
                            <img
                                alt="Trabus logo"
                                src={TrabusIcon}
                                height={90}
                                className="mb-0"
                            />
                            <div style={{
                                width: 2,
                                height: 90,
                                margin: '0 15px',
                                backgroundColor: 'white'
                            }}></div>
                            <img
                                alt="Trabus logo"
                                src={RipplegoLogo}
                                height={90}
                                className="m-0"
                            />
                        </div>
                        </Link>
                        <p className="logo-text">{GlobalJSON.ripplegoSlogan}</p>
                    </Col>
                    <Col lg={4}>
                        <h6 className="my-3" style={{color: 'white', fontWeight: 500}}>COMPANY</h6>
                            <div className="d-flex flex-column">
                                <a href="https://www.trabus.com/our-history" className="mb-1">About Us</a>
                                <Link to="/contact" className="mb-1">Contact Us</Link>
                            </div>
                    </Col>
                    <Col lg={4}>
                        <h6 className="my-3" style={{color: 'white', fontWeight: 500}}>SOCIAL MEDIA</h6>
                        <div className="d-flex justify-content-center">
                            <a className="d-flex mx-1 justify-content-center align-items-center social-icon"
                               target="_blank" rel="noopener noreferrer"
                               href="https://www.facebook.com/Trabus-Technologies-143543429114409/">
                                <FontAwesomeIcon className="" style={{fontSize: "20px", color: "white"}} icon={faFacebookF} />
                            </a>
                            <a className="d-flex mx-1 justify-content-center align-items-center social-icon"
                               target="_blank" rel="noopener noreferrer"
                               href="https://www.linkedin.com/company/trabus-technologies">
                                <FontAwesomeIcon className="" style={{fontSize: "20px", color: "white"}} icon={faLinkedinIn} />
                            </a>
                            <a className="d-flex mx-1 justify-content-center align-items-center social-icon"
                               target="_blank" rel="noopener noreferrer"
                               href="https://www.youtube.com/channel/UCttreg0QOabPfbfYPh3AHKA">
                                <FontAwesomeIcon className="" style={{fontSize: "20px", color: "white"}} icon={faYoutube} />
                            </a>
                            <a className="d-flex mx-1 justify-content-center align-items-center social-icon"
                               target="_blank" rel="noopener noreferrer"
                               href="https://twitter.com/Trabus_Tech">
                                <FontAwesomeIcon className="" style={{fontSize: "20px", color: "white"}} icon={faTwitter} />
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="w-100 text-center">
                <small className="copyright">Copyright &copy; {new Date().getFullYear()} TRABUS Technologies. All Rights Reserved.</small>
            </div>
        </div>
    )
}
